import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactSection extends Component {
  state = {
    data: {
      heading: "Keep Updated",
      headingText:
        "Discover the latest updates and news to stay informed about our platform and offerings.",
      // headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
      content:
        "Looking to get in touch with us? Feel free to reach out through the contact form  or visit our office. We value your feedback and inquiries, and our team is here to assist you promptly.",
    },
    iconList: [
      // {
      //     id: 1,
      //     iconClass: "fas fa-home",
      //     text: "Vestibulum nulla libero, convallis, tincidunt suscipit diam, DC 2002",
      // },
      {
        id: 2,
        iconClass: "fas fa-phone-alt",
        text: "+447588161416",
      },
      {
        id: 3,
        iconClass: "fas fa-envelope",
        text: "business@mylitlist.app",
      },
    ],
  };

  render() {
    const { data, iconList } = this.state;

    return (
      <section id="contact" className="contact-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-heading text-center">
                <h2 className="text-capitalize" style={{ color: "#ff5733" }}>
                  {data.heading}
                </h2>
                <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                {/* <p className="d-block d-sm-none mt-4"style={{color:"#ff5733"}}>{data.headingTexttwo}</p> */}
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-12 col-md-5">
              <div className="contact-us">
                <p className="mb-3">{data.content}</p>
                <ul>
                  {iconList.map((item) => (
                    <li key={`ci_${item.id}`} className="py-2">
                      <a className="media" href="/#">
                        <div className="social-icon mr-3">
                          <i className={item.iconClass} />
                        </div>
                        <span className="media-body align-self-center">
                          {item.text}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 pt-4 pt-md-0">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;

import React, { Component } from "react";

class Work extends Component {
  state = {
    data: {
      heading: "Optimize Your College Lifestyle",
      headingText: "Seamless Solutions, Straight from Your Smartphone.",
      workData: [
        {
          id: 1,
          image: "/img/work_thumb_1.png",
          title: "Verified",
          content: "Only Verified College Accounts",
        },
        {
          id: 2,
          image: "/img/work_thumb_2.png",
          title: "Express Listing",
          content: "Post Your Item in a Blink",
        },
        {
          id: 3,
          image: "/img/work_thumb_3.png",
          title: "Trusted Community",
          content: "Trade Safely Amongst Peers",
        },
      ],
    },
  };

  render() {
    const { heading, headingText, workData } = this.state.data;

    return (
      <section className="section work-area bg-overlay overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              {/* Work Content */}
              <div className="work-content text-center">
                <h2 className="" style={{ color: "#ff5733" }}>
                  {heading}
                </h2>
                <p
                  className="text-white my-3 mt-sm-4 mb-sm-5"
                  style={{ color: "#ff5733" }}
                >
                  {headingText}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {workData.map((item, idx) => (
              <div key={`w_${idx}`} className="col-12 col-md-4">
                {/* Single Work */}
                <div className="single-work text-center p-3">
                  {/* Work Icon */}
                  <div className="work-icon">
                    <img className="avatar-md" src={item.image} alt="" />
                  </div>
                  <h3 className=" py-3" style={{ color: "#ff5733" }}>
                    {item.title}
                  </h3>
                  <p className="text-white">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <div
            class="d-flex justify-content-center align-items-center mx-auto"
            style={{ width: "300px", height: "200px" }}
          >
            <button
              class="col btn btn-orange-moon btn-rounded shadow"
              style={{ borderRadius: " 20px  0 20px 20px" }}
            >
              Coming Soon..
            </button>
          </div> */}
        </div>
      </section>
    );
  }
}

export default Work;

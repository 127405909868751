import React, { Component } from "react";

class FooterSection extends Component {
  state = {
    data: {
      image: "/img/logo-transparent-primary.png",
      text: "Discover the essence of simplicity and convenience. Embrace the effortless journey toward success",
      linkText_1: "Useful Links",
      linkText_2: "Product Help",
      linkText_3: "Download",
    },
    iconList: [
      {
        id: 1,
        link: "facebook",
        iconClass: "fab fa-facebook-f",
      },
      {
        id: 2,
        link: "twitter",
        iconClass: "fab fa-twitter",
      },
      {
        id: 3,
        link: "google-plus",
        iconClass: "fab fa-google-plus-g",
      },
      {
        id: 4,
        link: "vine",
        iconClass: "fab fa-vine",
      },
    ],
    footerLists: [
      {
        title: "Useful Links",
        items: [
          { id: 1, text: "Home" },
          { id: 2, text: "Features" },
          { id: 3, text: "Our Story" },
          { id: 4, text: "Universities" },
          // { id: 5, text: "Contact" },
        ],
      },
      {
        title: "Product Help",
        items: [
          { id: 1, text: "FAQ" },
          { id: 2, text: "Privacy Policy" },
          { id: 3, text: "Support" },
          { id: 4, text: "Terms & Conditions" },
          // { id: 5, text: "Contact" },
        ],
      },
    ],
    footerList_3: [
      // { id: 1, image: "/img/google-play-black.png" },
      { id: 2, image: "/img/appStore.svg" },
    ],
  };

  render() {
    const { data, iconList, footerList_3 } = this.state;

    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          <div className="footer-top ptb_100">
            <div className="container">
              <div className="row justify-content-around align-items-center">
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="footer-items">
                    {/* <a className="navbar-brand" href="/#">
                      <span
                        className="logo "
                        style={{
                          fontSize: "35px",
                          color: "#ff5733",
                          fontStyle: "italic",
                        }}
                      >
                        LitList
                      </span>
                    </a> */}

                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={data.image}
                        alt=""
                        style={{
                          height: "136px",
                          width: "238px",
                        }}
                      />
                    </a>
                    <p className="mt-2 mb-3">{data.text}</p>
                    {/* <div className="social-icons d-flex">
                      {iconList.map((item, idx) => (
                        <a key={`fi_${idx}`} className={item.link} href="/#">
                          <i className={item.iconClass} />
                          <i className={item.iconClass} />
                        </a>
                      ))}
                    </div> */}
                  </div>
                </div>
                {/* {footerLists.map((footerList, idx) => (
                                    <div key={`footerList_${idx}`} className="col-12 col-sm-6 col-lg-3">
                                        <div className="footer-items">
                                            <h3 className="footer-title mb-2" style={{ color: "#ff5733" }}>{footerList.title}</h3>
                                            <ul>
                                                {footerList.items.map((item) => (
                                                    <li key={`fli_${item.id}`} className="py-2">
                                                        <a href="/#">{item.text}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))} */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="footer-items">
                    <h3
                      className="footer-title mb-2"
                      style={{ color: "#ff5733" }}
                    >
                      {data.linkText_3}
                    </h3>
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {footerList_3.map((item) => (
                        <a
                          key={`flth_${item.id}`}
                          href="https://apps.apple.com/in/app/litlist-college-marketplace/id6499259283"
                          target="_blank"
                        >
                          <img
                            src={item.image}
                            alt=""
                            width={300}
                            height={200}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    <div className="copyright-left">
                      © LitList 2024 All rights reserved.
                    </div>
                    {/* <div className="copyright-right">
                                            Made with <i className="fas fa-heart" /> By <a href="/#">SaibbyWeb</a>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;

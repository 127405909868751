import React, { Component } from "react";

class ServiceSection extends Component {
  state = {
    data: {
      heading: "Unlock Exclusive Student Savings Near Your Campus",
      buttonText: "Learn More",
      thumbOne: "/img/heroright.png",
    },
    serviceData: [
      {
        id: 1,
        iconClass: "fab fa-buffer",
        text: "Tap into Exclusive Student Discounts",
      },
      {
        id: 2,
        iconClass: "fas fa-brush",
        text: "Access Exclusive Student Offers from Local and National Businesses with Litlist",
      },
      {
        id: 3,
        iconClass: "fas fa-burn",
        text: "We Have Some New Offers From Our Readers.",
      },
      {
        id: 4,
        iconClass: "fas fa-cart-arrow-down",
        text: "This Is Our Package From Years.",
      },
    ],
  };

  render() {
    return (
      <section className="section service-area bg-gray overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Service Text */}
              <div className="service-text pt-4 pt-lg-0">
                <h2
                  className="text-capitalize mb-4"
                  style={{ color: "#ff5733" }}
                >
                  {this.state.data.heading}
                </h2>
                {/* Service List */}
                <ul className="service-list">
                  {this.state.serviceData.map((item, idx) => {
                    return (
                      <div key={`so_${idx}`}>
                        {/* Single Service */}
                        <li className="single-service media py-2">
                          <div className="service-icon pr-4">
                            <span>
                              <i className={item.iconClass} />
                            </span>
                          </div>
                          <div className="service-text media-body">
                            <p>{item.text}</p>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
                {/* <a href="/our-story" className="btn btn-bordered mt-4" style={{background:"#ff5733"}}>{this.state.data.buttonText}</a> */}
              </div>
            </div>
            <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
              {/* Service Thumb */}
              <div className="service-thumb mx-auto">
                <img src={this.state.data.thumbOne} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ServiceSection;

import React, { Component } from 'react';

class Team extends Component {
    state = {
        data: {
            heading: "Our Team Experts",
            headingText: "Our team of experts brings a wealth of knowledge and experience to the table. we're dedicated to delivering excellence in every aspect of our work",
            // headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
        },
        teamData: [
            {
                id: 1,
                image: "/img/teamimg.png",
                title: "Junaid Hasan",
                teamPost: "Founder & CEO"
            },
            {
                id: 2,
                image: "/img/teamimg2.png",
                title: "Jassica William",
                teamPost: "Co-Founder"
            },
            {
                id: 3,
                image: "/img/teamimg.png",
                title: "Md. Arham",
                teamPost: "Web Designer"
            },
            {
                id: 4,
                image: "/img/teamimg.png",
                title: "Yasmin Akter",
                teamPost: "Graphic Designer"
            },
        ],
        teamIcons: [
            {
                id: 1,
                iconClass: "fab fa-facebook-f"
            },
            {
                id: 2,
                iconClass: "fab fa-twitter"
            },
            {
                id: 3,
                iconClass: "fab fa-google-plus-g"
            },
            {
                id: 4,
                iconClass: "fab fa-linkedin-in"
            },
        ],
    };

    render() {
        const { data, teamData, teamIcons } = this.state;

        return (
            <section className="section team-area team-style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-6">
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                                {/* <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {teamData.map((item) => (
                            <div key={`t_${item.id}`} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                                    <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                                        <img src={item.image} alt="" />
                                        <div className="team-overlay radius-100">
                                            <h4 className="team-name text-white">{item.title}</h4>
                                            <h5 className="team-post text-white mt-2 mb-3">{item.teamPost}</h5>
                                            <div className="team-icons">
                                                {teamIcons.map((icon) => (
                                                    <a key={`ti_${icon.id}`} className="p-2" href="/#">
                                                        <i className={icon.iconClass} />
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;

import React, { Component } from "react";

const featureData = [
  {
    id: 1,
    image: "/img/featured_image_1.png",
    title: "Operational Excellence",
    text: "Experience seamless functionality and efficiency tailored to your needs.",
  },
  {
    id: 2,
    image: "/img/featured_image_2.png",
    title: "Instant Support",
    text: "Connect with real-time assistance for immediate solutions and support.",
  },
  {
    id: 3,
    image: "/img/featured_image_3.png",
    title: "Security Assurance",
    text: "Rest assured with our commitment to safeguarding your information. Experience peace of mind knowing your data is protected.",
  },
];

class FeatureSection extends Component {
  state = {
    data: {},
    featureData: featureData,
  };

  render() {
    return (
      <section
        id="features"
        className="section features-area style-two overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              <div className="section-heading text-center">
                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                  <i className="far fa-lightbulb text-primary mr-1" />
                  <span className="" style={{ color: "#ff5733" }}>
                    Premium
                  </span>{" "}
                  Features
                </span>
                <h2 className="" style={{ color: "#ff5733" }}>
                  Turn Your Items into Cash with Ease
                </h2>
                <p className="d-none d-sm-block mt-4">
                  Capture, List, and Chat - All in a Seamless App Experience
                </p>
                {/* <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.featureData.map((item, idx) => {
              return (
                <div
                  key={`fo_${idx}`}
                  className="col-12 col-md-6 col-lg-4 res-margin"
                >
                  {/* Image Box */}
                  <div className="image-box text-center icon-1 p-5">
                    {/* Featured Image */}
                    <div className="featured-img mb-3">
                      <img className="avatar-sm" src={item.image} alt="" />
                    </div>
                    {/* Icon Text */}
                    <div className="icon-text">
                      <h3 className="mb-2" style={{ color: "#ff5733" }}>
                        {item.title}
                      </h3>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div
            class="d-flex justify-content-center align-items-center mx-auto"
            style={{ width: "300px", height: "200px" }}
          >
            <button
              class="col btn btn-orange-moon btn-rounded shadow"
              style={{ borderRadius: " 20px  0 20px 20px" }}
            >
              Coming Soon..
            </button>
          </div> */}
        </div>
      </section>
    );
  }
}

export default FeatureSection;

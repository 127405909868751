import React, { Component } from "react";
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.myForm = React.createRef();
  }
  state = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  // formData = {
  //   name: this.state.name,
  //   email: this.state.email,
  //   subject: this.state.subject,
  //   message: this.state.message,
  // };

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    console.log(this.state);

    fetch("https://saibbyweb.com/sendEmailToLitListAdmin", {
      method: "POST",
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.myForm.current.reset();
        this.setState({
          name: "",
          email: "",
          phone: "",
        });
      })
      .catch((e) => console.log(e));
  };

  render() {
    return (
      <div className="contact-box text-center">
        <form
          ref={this.myForm}
          onSubmit={this.submitHandler}
          className="contact-form"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder="Phone"
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.phone}
                />
              </div>
            </div>
            {/* <div className="col-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Message"
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.message}
                />
              </div>
            </div> */}
            <div className="col-12 ">
              <button type="submit" className="btn btn-lg btn-block mt-3">
                <span className="text-white pr-3 ">
                  <i className="fas fa-paper-plane" />
                </span>
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;

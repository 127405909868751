import React from "react";
// import Modal from "../Modal/Modal";

const initData = {
  heading: "Discover Your Campus Exchange Hub",
  content:
    "Experience the premier college-exclusive hub for trading, buying, and leasing among fellow students",
  headingSlug: "*Coming Soon to App Store",
  // playImg: "/img/google-play.png",
  appleImg: "/img/appStore.svg",
  heroThumb: "/img/heroright.png",
};

function HeroSection() {
  // const [isVisible, setIsVisible] = React.useState(false);
  // const viewModal = () => {
  //   setIsVisible(true);
  // };

  // const closeModal = () => {
  //   setIsVisible(false);
  // };

  return (
    <section
      id="Home"
      className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
    >
      {/* <div style={{ position: "relative" }}>
        <Modal openModal={isVisible}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <h1 style={{ fontSize: "17px" }}>Stay Connected with Us</h1>
            <button
              style={{
                fontSize: "30px",
                background: "transparent",
                border: "none",
                color: "black",
              }}
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </Modal>
      </div> */}
      <div className="container">
        <div className="row align-items-center">
          {/* Welcome Intro Start */}
          <div className="col-12 col-md-7 col-lg-6">
            <div className="welcome-intro" style={{}}>
              {/* <h1 className="text-white">{this.state.data.heading}</h1> */}
              <h1 className="text-white">{initData.heading}</h1>
              {/* <p className="text-white my-4">{this.state.data.content}</p> */}
              <p className="text-white my-4">{initData.content}</p>
              {/* Store Buttons */}
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                  gap: "10px",
                }}
              >
                <div
                  className="button-group store-buttons d-flex multiply-blend"
                  style={{}}
                >
                  {/* <a href="/#">
                            <img src={this.state.data.playImg} alt="" />
                            </a> */}
                  <a
                    href="https://apps.apple.com/in/app/litlist-college-marketplace/id6499259283"
                    target="_blank"
                  >
                    <img
                      src={initData.appleImg}
                      alt="App Store"
                      width={300}
                      height={200}
                    />
                  </a>
                </div>
                {/* <div
                  style={{
                    width: "150px",
                    height: "58px",
                    padding: "2px",
                  }}
                >
                  <button
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid white",
                      borderRadius: "8px",
                      background: "transparent",
                      padding: "5px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={viewModal}
                  >
                    Get Notified
                  </button>
                </div> */}
              </div>
              <span className="d-inline-block t fw-3 font-italic mt-3 text-white">
                {/* {initData.headingSlug} */}
                {/* {this.state.data.headingSlug} */}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6">
            {/* Welcome Thumb */}
            <div className="welcome-thumb mx-auto">
              <img
                src={
                  // {this.state.data.heroThumb}
                  initData.heroThumb
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shape-bottom">
        <svg
          viewBox="0 0 1920 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="svg replaced-svg"
        >
          <title>sApp Shape</title>
          <desc>Created with Sketch</desc>
          <defs />
          <g
            id="sApp-Landing-Page"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="sApp-v1.0"
              transform="translate(0.000000, -554.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                id="sApp-v1.0"
              />
            </g>
          </g>
        </svg>
      </div>
    </section>
  );
}
// }

export default HeroSection;

import React, { Component } from 'react';

class DiscoverSection extends Component {
    state = {
        data: {
            themeOneServiceSection: {
                headingFour: "More productivity with less effort",
                thumbFour: "/img/discover_thumb.png",
                serviceData: [
                    {
                        id: 1,
                        iconClass: "fab fa-buffer",
                        text: "Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
                    },
                    {
                        id: 2,
                        iconClass: "fas fa-brush",
                        text: "Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
                    },
                    {
                        id: 3,
                        iconClass: "fas fa-burn",
                        text: "Drop ipsum dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
                    },
                    {
                        id: 4,
                        iconClass: "fas fa-cart-arrow-down",
                        text: "Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
                    }
                ]
            }
        }
    };

    render() {
        const { headingFour, thumbFour, serviceData } = this.state.data.themeOneServiceSection;

        return (
            <section className="section discover-area bg-purple overflow-hidden ptb_100" style={{ backgroundColor: "#7c4fe0" }}>
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 order-lg-2">
                <div className="service-thumb discover-thumb mx-auto mt-4 mt-lg-0">
                    <img src={thumbFour} alt="" className="rounded" />
                </div>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
                <div className="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                    <h2 className="pb-4 text-white">{headingFour}</h2>
                    <ul className="service-list style-two text-white">
                        {serviceData.map((item) => (
                            <li key={item.id} className="single-service py-2">
                                <div className="service-text">
                                    <p className='text-white'>{item.text}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

        );
    }
}

export default DiscoverSection;

import React from "react";

const UnvData = [
  {
    name: "University Of Leeds",
    Location: "London",
    description:
      "Founded in 1904, with a campus capacity of over 31,000 students.",
    image: "/img/leeds1.jpeg",
  },
  {
    name: "University Of Warwick",
    Location: "London",
    description:
      "The university was founded in 1965 as part of a government initiative.",
    image: "/img/warwick.jpeg",
  },
  {
    name: "University Of York",
    Location: "London",
    description:
      "The University of York is a collegiate research university in York, England.",
    image: "/img/york.jpeg",
  },
  {
    name: "University Of Bristol",
    Location: "London",
    description:
      "Founded in 1595 and University College, which had been in existence since 1876.",
    image: "/img/bristol.jpeg",
  },
  {
    name: "Newcastle University",
    Location: "London",
    description:
      "Newcastle University is a public research university based in Newcastle.",
    image: "/img/newcastle.jpeg",
  },
  {
    name: "University Of Reading",
    Location: "London",
    description:
      "The University of Reading is a public research university in Reading, Berkshire, England.",
    image: "/img/reading.jpeg",
  },
];
const UniversitiesSection = () => {
  return (
    <div className="container mt-5">
      <div className="d-flex flex-column align-items-center">
        <h2 className="mb-4" style={{ color: "#ff5733" }}>
          Our Partner Universities
        </h2>
        <p className="text-center mb-4 fs-5 fs-sm-2 fs-md-3 fs-lg-4">
          Explore the many universities we collaborate...
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-around">
        {UnvData.map((university, index) => (
          <UniversityCard
            key={index}
            name={university.name}
            Location={university.Location}
            description={university.description}
            Image={university.image}
          />
        ))}
      </div>
    </div>
  );
};

const UniversityCard = ({ name, Location, description, Image }) => {
  return (
    <div id="University" className="card mb-4" style={{ width: "18rem" }}>
      <img src={Image} className="card-img-top" alt="University" />
      <div className="card-body">
        <h5 className="card-title " style={{ color: "#ff5733" }}>
          {name}
        </h5>
        <h5>{Location}</h5>
        <p className="card-text mt-2">{description}</p>
      </div>
    </div>
  );
};

export default UniversitiesSection;

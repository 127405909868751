import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          {/* <a className="navbar-brand navbar-brand-regular" href="/">
            <h1
              className="text-gray font-italic font-weight-bold"
              style={{ fontSize: "40px", color: "#ff5733" }}
            >
              LitList
            </h1>
          </a> */}
          <a className="navbar-brand navbar-brand-regular" href="/#">
            <img
              className="logo"
              src={"/img/logo-transparent-white.png"}
              alt=""
              style={{
                height: "97px",
                width: "170px",
              }}
            />
          </a>
          <a className="navbar-brand navbar-brand-sticky" href="/">
            <img
              className="logo"
              src={"/img/logo-transparent-primary.png"}
              alt=""
              style={{
                height: "97px",
                width: "170px",
              }}
            />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            {/*  Mobile Menu Toggler */}
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link scroll" href="#Home">
                    Home
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link scroll" href="#features">
                    Features
                  </a>
                </li>

                <li className="nav-item">
                  {/* <Link to="/our-story" className="nav-link scroll">Our Story</Link> */}
                  {/* <Link className="nav-link" to="#">
                    Our Story
                  </Link> */}
                  {/* <a className="nav-link scroll" href="/our-story" >Our Story</a> */}
                </li>

                <li className="nav-item">
                  <a className="nav-link scroll" href="#University">
                    Universities
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

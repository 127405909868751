import React, { Component } from "react";

const initData = {
  heading: "We're here, download now!",
  headingText:
    "Experience the convenience of litlist on any device. Whether you're on a smartphone, tablet, or computer, access our platform effortlessly for a seamless user experience",
  // headingTexttwo: "sApp is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.",
  headingSlug: "*Coming Soon to App Store",
  // playImg: "/img/google-play.png",
  appleImg: "/img/appStore.svg",
};

class Download extends Component {
  state = {
    initData: {},
  };
  componentDidMount() {
    this.setState({
      initData: initData,
    });
  }
  render() {
    return (
      <section
        id="Download"
        className="section download-area overlay-dark ptb_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Download Text */}
              <div className="download-text text-center">
                <h2 className="" style={{ color: "#ff5733" }}>
                  {this.state.initData.heading}
                </h2>
                <p className="text-white my-3 d-none d-sm-block">
                  {this.state.initData.headingText}
                </p>
                {/* <p className="text-white my-3 d-block d-sm-none">{this.state.initData.headingTexttwo}</p> */}
                {/* Store Buttons */}
                <div className="button-group store-buttons d-flex justify-content-center rounded">
                  {/* <a href="/#">
                            <img src={this.state.initData.playImg} alt="" />
                            </a> */}
                  <a
                    href="https://apps.apple.com/in/app/litlist-college-marketplace/id6499259283"
                    target="_blank"
                  >
                    <img
                      src={this.state.initData.appleImg}
                      alt=""
                      width={300}
                      height={200}
                    />
                  </a>
                </div>
                {/* <span className="d-inline-block e fw-3 font-italic mt-3 text-white">
                  {this.state.initData.headingSlug}
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Download;

import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import FooterSection from '../FooterSection/Footer';
import Team from '../TeamSection/Team';
import DiscoverSection from '../DiscoverSection/DiscoverTwo';

const initData = {
    heading: "Your download should begin automatically",
    content: "If it doesn’t start automatically, please click",
    link: "here",
    content_2: "to downlaod manually.",
    image: "/img/features_thumb.png"
}

class StoryPage extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }


    render() {
        return (
            <>
            <div className="homepage-2 download-page inner inner-pages">
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    {/* <section className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
                        
                    </section> */}
                    <DiscoverSection/>
                    <Team/>
                </div>
            </div>
            <FooterSection />
            </>
        );
    }
}

export default StoryPage; 